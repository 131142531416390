import { PharmacyDetails } from "@/models/pharmacy";
import DataService from "@/services/data_service";
import { ref } from "vue";

const loading = ref<boolean>(false);

export default (): any => {
  const pharmacy = ref<PharmacyDetails | null>(null);

  const fetchPharmacyDetails = async (
    pharmacyID: number,
    callback?: (pharma: PharmacyDetails) => void
  ) => {
    try {
      loading.value = true;
      const { data } = await DataService.getPharmacyDetailsById(pharmacyID);

      pharmacy.value = data.objectResponse;
      console.log(`pharmacy`, data.objectResponse);

      if (callback && pharmacy.value) {
        callback(pharmacy.value);
      }
    } catch (err: any) {
      const errorMsg = err?.response?.data.resultMessage || err.message;
      console.error(errorMsg);
    } finally {
      loading.value = false;
    }
  };

  return {
    fetchPharmacyDetails,
    pharmacy,
    loading,
  };
};
