import { RouteRecordRaw } from "vue-router";

export const getRouteByName = (name: string, routes: RouteRecordRaw[]) => {
  const route = routes.find((route) => route.name === name);
  if (route) {
    return {
      to: route.path,
      label: route?.meta?.label || route.name,
    };
  }
};
